import { useState } from 'react';
import { Div, Button, Modal, Icon, Text, Input } from "atomize";
import { addSubscriber } from "../utils/firebase";
// import { SHA3 as sha3} from 'sha3'; 

export const LearnMoreModal = ({ isOpen, onClose }) => {
	const [email, setEmail] = useState("");
	// const [wallet, setWallet] = useState("");
	const [submitted, setSubmitted] = useState(false);

	// REF: https://ethereum.stackexchange.com/questions/1374/how-can-i-check-if-an-ethereum-address-is-valid
	// const isETHAddress = function(address) {
	// 	if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
	// 		// check if it has the basic requirements of an address
	// 		return false;
	// 	} else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
	// 		// If it's all small caps or all all caps, return true
	// 		return true;
	// 	} else {
	// 		// Otherwise check each case
	// 		console.log(address);
	// 		// return isChecksumAddress(address);
	// 	}
	// }

	// REF: https://ethereum.stackexchange.com/questions/1374/how-can-i-check-if-an-ethereum-address-is-valid
	// var isChecksumAddress = function (address) {
	// 	// Check each case
	// 	address = address.replace('0x','');
	// 	var addressHash = sha3(address.toLowerCase());
	// 	for (var i = 0; i < 40; i++ ) {
	// 		// the nth letter should be uppercase if the nth digit of casemap is 1
	// 		if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
	// 			return false;
	// 		}
	// 	}
	// 	return true;
	// };

	const isEmail = function (email) {
		const reg = /\S+@\S+\.\S+/;
		if(reg.test(email)) {
			return true;
		}

		return false;
	}

	const handleSubmit = () => {
		// Check Email
		if (!isEmail(email)) {
			alert("Email Not Valid");
			return false;
		}

		// Check ETH Wallet Address
		/*
		if (wallet == "" || wallet == null) {
			if (!window.confirm("Heads up!  You are about to submit without a wallet address.")) {
				return false;
			}
		} else if (!isETHAddress(wallet)) {
			alert("Your ETH address is invalid.");
			return false;
		}
		*/

		// All Checks Passed!
		addSubscriber(null, null, email, "");
		setSubmitted(true);
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} align="center" rounded="md">
			{submitted ? 
			<>
				<Icon
					name="Cross"
					pos="absolute"
					top="1rem"
					right="1rem"
					size="16px"
					onClick={onClose}
					cursor="pointer"
				/>
				<Div d="flex" flexDir="row" m={{ b: "0.5rem" }}>
					{/* <Icon
						name="AlertSolid"
						color="warning700"
						m={{ t: "0.35rem", r: "0.5rem" }}
					/> */}
					<Text p={{ t: "0.25rem" }} textSize="subheader">
						Thanks for joining! &nbsp;Say hi on 
					</Text>
					<a href="https://twitter.com/NoComma_" target="_blank" rel="noreferrer">
						<Button
							p="0.25rem"
							bg="transparent"
						>
							<Icon name="Twitter" size="1.5rem" color="black" hoverColor="white" />
						</Button>
					</a>
				</Div>
				<Div d="flex" justify="flex-end" m={{ t: "2rem" }}>
					<Button 
						onClick={onClose}
						bg="gray300"
						textColor="black"
						// shadow="3"
						hoverBg="black"
						hoverTextColor="white"
						hoverShadow="5"
					>
						You're Welcome!
					</Button>
				</Div>
			</>
			: 
			<>
			<Icon
				name="Cross"
				pos="absolute"
				top="1rem"
				right="1rem"
				size="16px"
				onClick={onClose}
				cursor="pointer"
			/>
			<Div d="flex" m={{ b: "0.5rem" }}>
				<Text p={{ t: "0.25rem" }} textSize="subheader">
					We're working on the NFT! &nbsp;Sign up for <strong>No Comma Club</strong> waitlist
				</Text>
			</Div>
			<Div>
				<Div m={{ b: "0.5rem" }}>
					<Input placeholder="email" type="email" value={email} onChange={e => setEmail(e.target.value)}/>
				</Div>
				{/* <Div>
					<Input placeholder="wallet" value={wallet} onChange={e => setWallet(e.target.value)}/>
				</Div> */}
			</Div>
			<Div d="flex" justify="flex-end" m={{ t: "2rem" }}>
				<Button
					onClick={onClose}
					bg="gray200"
					hoverBg="gray400"
					textColor="medium"
					m={{ r: "1rem" }}
				>
					Cancel
				</Button>
				<Button 
					onClick={handleSubmit}
					bg="gray300"
					textColor="black"
					// shadow="3"
					hoverBg="black"
					hoverTextColor="white"
					hoverShadow="5"
				>
					Yes, Submit
				</Button>
			</Div>
			</>
		}
		</Modal>
	);
};

export function LearnMore() {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<Button
				w="20vw" minW="110px"
				p="1rem"
				bg="white"
				rounded="md"
				textColor="black"
				shadow="5"
				hoverBg="black"
				hoverTextColor="white"
				hoverShadow="3"
				onClick={() => setShowModal(true)}
			>
				Learn More
			</Button>
			<LearnMoreModal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
			/>
		</>
	);
}