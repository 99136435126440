import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDjFgyovToLxd-XBFQzSE-1SrNAMq1-VRY",
  authDomain: "nocommaclub-129b5.firebaseapp.com",
  databaseURL: "https://nocommaclub-129b5-default-rtdb.firebaseio.com",
  projectId: "nocommaclub-129b5",
  storageBucket: "nocommaclub-129b5.appspot.com",
  messagingSenderId: "616808896858",
  appId: "1:616808896858:web:24bc2235117e73a77ffb63",
  measurementId: "G-N1QVQ2VFNY"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getDatabase();

export function addSubscriber(firstName, lastName, email, wallet) {
  const uid = uuidv4();
  console.log(uid);
  const subs = ref(db, 'subs/' + uid);
  set(subs, { firstName: firstName, lastName: lastName, email: email, wallet: wallet });
}

addSubscriber("Bob", "Bill", "some@ufg.edu", " ");
console.log("Sub Added?");
