import './App.css';
import React, { useRef, useState } from 'react';
import { Div, Icon, Button } from 'atomize';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
// import Scene from './components/NoComma';
import NoCommaVideo from './assets/NoCommaClub_v3_5s.mp4';
import NoCommaSign from './assets/NoComma.png';
import NotAComma from './assets/NotAComma.png';
import NotADot from './assets/NotADot.png';
import NorThree from './assets/NorThree.png';
import JKItIs from './assets/JKItIs.png';
import THEimage from './assets/THE.png';
import NFTimage from './assets/NFT.png';
// import { FloatingInfo, AboutInfoOne, AboutInfoTwo } from './components/FloatingInfo';
import { LearnMoreModal } from './components/LearnMore';

function App() {
  const parallax = useRef();
  const noCommaRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [showJKInfo, setShowJKInfo] = useState(false);
  const [showNTInfo, setShowNTInfo] = useState(false);

  return (
    <div style={{ width: '100vw', height: '100vh', background: '#ffffff' }}>
      <Parallax ref={parallax} pages={3}>

        <ParallaxLayer
          offset={0}
          speed={2}
          onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "#ffffff",
            zIndex: "9999"
          }}
        >
          <>
          <video className="noCommaVideo" width="100%" poster="NoCommaClub.jpg" playsInline autoPlay muted alt="The 'No Comma Club' handwritten, representing the simplicity and unique creativity inherent in the No Comma Club.">
            <source src={`${NoCommaVideo}#t=0.1`} type="video/mp4" />
          </video>
          <Icon name="DownArrow" className="carrot" size="2rem" cursor="pointer" pos="absolute" top="90%"/>
          </>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-1.1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            pointerEvents: 'none'
        }}>
          <Div ref={noCommaRef} className="scene" pos="absolute"
            d="flex" justify="center" align="center"
          >
            <Div className="noComma" h="10vw" w="10vw" rounded="circle" bg="black" />
            {/* <Scene noComma={{scale: 1, color: "#000000", extRef: noCommaRef}}/> */}
          </Div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.1}
          speed={4}
          factor={0}
          style={{
            display: 'flex',
            justifyContent: 'center',
            zIndex: "1"
          }}
        >
          {/* <FloatingInfo
            pos="absolute" left="7vw"
            titleText="Not a Comma"
            infoHeader="Nor is it three"
            infoText={<AboutInfoOne />}
          /> */}
          <Div 
          p="0rem" w="40vw"
          d="flex" justify="center" align="center" flexDir="column"
          bg="transparent"
          rounded="md"
          // shadow="5"
          pos="absolute"
          top="65%"
          left="1vw"
          onMouseEnter={() => setShowNTInfo(true)}
          // onMouseLeave={() => setShowNTInfo(false)}
          >
            <>
            <img src={NotAComma} className="notAComma" alt="the down-to-earth, handwritten 'No Comma'"/>
            {showNTInfo ?
              <img src={NorThree} className="infoText notAComma" alt="" style={{width: "15vw"}}/>
              : false
            }
            </>
          </Div>
          
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.25}
          speed={4}
          factor={0}
          style={{
            display: 'flex',
            justifyContent: 'center',
            zIndex: "1"
          }}
        >
          {/* <FloatingInfo
            pos="absolute" right="7vw"
            titleText="Not a Dot"
            infoText={<AboutInfoTwo />}
          /> */}
          <Div 
          p="0rem" w="40vw"
          d="flex" justify="center" align="center" flexDir="column"
          bg="transparent"
          rounded="md"
          // shadow="5"
          pos="absolute"
          top="65%"
          right="1vw"
          onMouseEnter={() => setShowJKInfo(true)}
          // onMouseLeave={() => setShowJKInfo(false)}
          >
            <>
            <img src={NotADot} className="notAComma" alt="the down-to-earth, handwritten 'No Comma'"/>
            {showJKInfo ?
              <img src={JKItIs} className="infoText notAComma" alt="" style={{width: "15vw"}}/>
              : false
            }
            </>
          </Div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={2}
          onClick={() => parallax.current.scrollTo(2)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Div 
          p="1rem" w="40vw"
          d="flex" justify="center" align="center" 
          bg="transparent"
          rounded="md"
          // shadow="5"
          pos="absolute"
          top="65%"
          left="30%"
          >
            <>
            <img src={NoCommaSign} className="noCommaSign" alt="the down-to-earth, handwritten 'No Comma'"/>
            </>
          </Div>
          <Icon name="DownArrow" className="carrot" size="2rem" cursor="pointer" pos="absolute" top="90%"/>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "-1"
          }}
        >
          <Div className="nft" pos="absolute" bg="white" shadow="5">
          </Div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={5}
          style={{
            display: "flex",
            // alignItems: "center"
          }}
        >
          <Div className="theNFT">
            <img src={THEimage} width="50%" alt="handwritten 'The'" />
          </Div>
          <Div className="theNFT" right="0vw">
            <img src={NFTimage} width="75%" alt="handwritten 'NFT' standing for Non Fungible Token" />
          </Div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Div pos="absolute" top="70vh" d="flex" flexDir="column" align="center">
            {/* <Button
              w="20vw" minW="110px"
              p="1rem"
              bg="white"
              rounded="md"
              textColor="black"
              shadow="5"
              hoverBg="black"
              hoverTextColor="white"
              hoverShadow="3"
              onClick={() => setShowModal(true)}
            >
              Learn More
            </Button> */}
            <a href="https://nocommaclub.substack.com/" target="_blank" rel="noreferrer" style={{"text-decoration": "none"}}>
              <Button
                w="20vw" minW="110px"
                p="1rem"
                bg="white"
                rounded="md"
                textColor="black"
                shadow="5"
                hoverBg="black"
                hoverTextColor="white"
                hoverShadow="3"
              >
                Learn More
              </Button>
            </a>
            
            
            <a href="https://twitter.com/TheNoCommaClub" target="_blank" rel="noreferrer">
              <Button
                bg="transparent" m="0.5rem"
              >
                <Icon name="Twitter" size="1.5rem" color="black" hoverColor="white" />
              </Button>
            </a>
            {/* <a href="https://opensea.io/" target="_blank" rel="noreferrer">
              <Button
                bg="transparent" m="0.5rem"
              >
                <Icon name="Twitter" size="1.5rem" color="black" hoverColor="white" />
              </Button>
            </a> */}
          </Div>
        </ParallaxLayer>
        
        {/* <ParallaxLayer
          offset={2.9}
          speed={7}
          factor={0.01}
        >
          <Text textSize="tiny" pos="absolute" right="2%" p="1rem">design by donaut</Text>
          <Text textSize="tiny" pos="absolute" bottom="2%" right="2%" p="1rem">design by donaut</Text>
        </ParallaxLayer> */}
        
    </Parallax>
    <LearnMoreModal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
    />
    </div>
  );
}

export default App;